import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      {/* <h2 className="head-text">Turning Data into Insights: <span> Crafting Your</span> <br /> Tomorrow, Today.</h2> */}
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className='app__header-info'>
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <div style={{ marginLeft: 20 }}>
              <h3 className='p-text'>
                I am Sabiran Thapa, a dedicated AI Developer with a passion for leveraging technology to solve real-world problems. With over 2 years of experience in software development across diverse industries, I possess a strong foundation in artificial intelligence and machine learning. My journey has been shaped by a commitment to continuous learning, collaboration, and innovation.
              </h3>
            </div>
          </div>
        </div>
      </motion.div>
      
      <a href="frontend_react/src/assets/sabiran-master-resume.docx" download="Sabiran_Thapa_Resume.pdf">
        <button>Download Resume</button>
      </a>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);